import { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from 'src/setup'
import { AsideMenuItem } from 'src/_metronic/layout/components/aside/AsideMenuItem'
import { AsideMenuItemWithSub } from 'src/_metronic/layout/components/aside/AsideMenuItemWithSub'
import { ModulePermissionQuery, UserModel } from '../modules/auth/models/UserModel'
import DashboardScreens from '../modules/dashboard/DashboardScreens'
import LoyaltyInfoScreens from '../modules/loyalty-info/LoyaltyInfoScreens'
import PadmaMemberScreens from '../modules/padma-member/PadmaMemberScreens'
import PromotionBannerScreens from '../modules/promotion-banner/PromotionBannerScreens'
import PropertyScreens from '../modules/property/PropertySceens'
import SettingsScreens from '../modules/settings/SettingsScreens'
import UserManagementScreens from '../modules/user-managment/UserManagementScreens'
import OrderSettingsScreens from '../modules/order-settings/OrderSettingsScreens'
import ReportsScreens from '../modules/reports/ReportsScreens'

interface Menu {
  id?: string
  to?: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  children?: Menu[]
  module_permissions?: ModulePermissionQuery
  activePath?: string
  badge?: string
  badgeCircle?: boolean
  hidden?: boolean
}

const useMenus = (user?: any): Menu[] => {
  return useMemo(
    () =>
      [
        {
          title: '',
          children: [
            {
              to: DashboardScreens.DASHBOARD.PATH,
              icon: '/media/icons/padma/dashboard.svg',
              title: 'Dashboard',
              module_permissions: DashboardScreens.DASHBOARD.PERMISSION,
            },
            {
              to: ReportsScreens.REPORT.PATH,
              icon: '/media/icons/padma/IconReports.svg',
              title: 'Report',
              children: [
                {
                  to: ReportsScreens.LIST_BOOKING_REPORT.PATH,
                  activePath: ReportsScreens.LIST_BOOKING_REPORT.PATH,
                  title: ReportsScreens.LIST_BOOKING_REPORT.TITLE,
                  hasBullet: true,
                  module_permissions: ReportsScreens.LIST_BOOKING_REPORT.PERMISSION,
                },
                {
                  to: ReportsScreens.LIST_QUEUE_REPORT.PATH,
                  activePath: ReportsScreens.LIST_QUEUE_REPORT.PATH,
                  title: ReportsScreens.LIST_QUEUE_REPORT.TITLE,
                  hasBullet: true,
                  module_permissions: ReportsScreens.LIST_QUEUE_REPORT.PERMISSION,
                },
                {
                  to: ReportsScreens.LIST_ABANDONED_BOOKING_REPORT.PATH,
                  activePath: ReportsScreens.LIST_ABANDONED_BOOKING_REPORT.PATH,
                  title: ReportsScreens.LIST_ABANDONED_BOOKING_REPORT.TITLE,
                  hasBullet: true,
                  module_permissions: ReportsScreens.LIST_ABANDONED_BOOKING_REPORT.PERMISSION,
                }
              ]
            },
            {
              to: UserManagementScreens.USER_ROLE.PATH,
              icon: '/media/icons/padma/IconUser.svg',
              title: UserManagementScreens.USER_ROLE.TITLE,
              module_permissions: UserManagementScreens.USER_ROLE.PERMISSION,
              children: [
                {
                  to: UserManagementScreens.LIST_ROLE.PATH,
                  activePath: UserManagementScreens.LIST_ROLE.PATH,
                  title: UserManagementScreens.LIST_ROLE.TITLE,
                  hasBullet: true,
                  module_permissions: UserManagementScreens.LIST_ROLE.PERMISSION,
                },
                {
                  to: UserManagementScreens.LIST_USER.PATH,
                  activePath: UserManagementScreens.LIST_USER.PATH,
                  title: UserManagementScreens.LIST_USER.TITLE,
                  hasBullet: true,
                  module_permissions: UserManagementScreens.LIST_USER.PERMISSION,
                }
              ],
            },
            {
              to: PadmaMemberScreens.LIST_MEMBER.PATH,
              icon: '/media/icons/padma/IconMenuMember.svg',
              title: 'Padma Member',
              module_permissions: PadmaMemberScreens.LIST_MEMBER.PERMISSION,
            },
            {
              to: PropertyScreens.LIST_PROPERTY.PATH,
              icon: '/media/icons/padma/IconProperty.svg',
              title: 'Property List',
              module_permissions: PropertyScreens.LIST_PROPERTY.PERMISSION
            },
            {
              to: LoyaltyInfoScreens.LIST_LOYALTY_INFO.PATH,
              icon: '/media/icons/padma/IconVoucher.svg',
              title: 'Loyalty Info',
              module_permissions: LoyaltyInfoScreens.LIST_LOYALTY_INFO.PERMISSION,
            },
            {
              to: PromotionBannerScreens.LIST_PROMOTION_BANNER.PATH,
              icon: '/media/icons/padma/IconPromotionBanner.svg',
              title: PromotionBannerScreens.LIST_PROMOTION_BANNER.TITLE,
              module_permissions: PromotionBannerScreens.LIST_PROMOTION_BANNER.PERMISSION,
            },
            {
              to: OrderSettingsScreens.ORDER_SETTINGS.PATH,
              icon: '/media/icons/padma/IconOrderSettings.svg',
              title: OrderSettingsScreens.ORDER_SETTINGS.TITLE,
              module_permissions: OrderSettingsScreens.ORDER_SETTINGS.PERMISSION,
            },
            {
              to: SettingsScreens.SETTINGS.PATH,
              icon: '/media/icons/padma/IconSettings.svg',
              title: SettingsScreens.SETTINGS.TITLE,
              children: [
                {
                  to: SettingsScreens.LIST_NOTIFICATION.PATH,
                  activePath: SettingsScreens.LIST_NOTIFICATION.PATH,
                  title: SettingsScreens.LIST_NOTIFICATION.TITLE,
                  hasBullet: true,
                  module_permissions: SettingsScreens.LIST_NOTIFICATION.PERMISSION,
                },
                {
                  to: SettingsScreens.LIST_FAQ_CATEGORY.PATH,
                  activePath: SettingsScreens.FAQ.PATH,
                  title: SettingsScreens.FAQ.TITLE,
                  hasBullet: true,
                  module_permissions: SettingsScreens.FAQ.PERMISSION,
                },
                {
                  to: SettingsScreens.ABOUT_US.PATH,
                  activePath: SettingsScreens.ABOUT_US.PATH,
                  title: SettingsScreens.ABOUT_US.TITLE,
                  hasBullet: true,
                  module_permissions: SettingsScreens.ABOUT_US.PERMISSION,
                },
                {
                  to: SettingsScreens.CONTACT_US.PATH,
                  activePath: SettingsScreens.CONTACT_US.PATH,
                  title: SettingsScreens.CONTACT_US.TITLE,
                  hasBullet: true,
                  module_permissions: SettingsScreens.CONTACT_US.PERMISSION,
                },
                {
                  to: SettingsScreens.TNC.PATH,
                  activePath: SettingsScreens.TNC.PATH,
                  title: SettingsScreens.TNC.TITLE,
                  hasBullet: true,
                  module_permissions: SettingsScreens.TNC.PERMISSION,
                },
                {
                  to: SettingsScreens.PRIVACY_POLICY.PATH,
                  activePath: SettingsScreens.PRIVACY_POLICY.PATH,
                  title: SettingsScreens.PRIVACY_POLICY.TITLE,
                  hasBullet: true,
                  module_permissions: SettingsScreens.PRIVACY_POLICY.PERMISSION,
                },
              ],
            },
          ]
        },
      ] as Menu[],
    [user]
  )
}

const GeneratedMenu: React.FC<{ menu: Menu }> = ({ menu }) => {
  if (!menu.to) {
    if (!menu.children || menu.children.length === 0) return null
    return (
      <>
        <div className='menu-item'>
          <div className='menu-content'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{menu.title}</span>
          </div>
        </div>
        {menu.children?.map((child) => {
          return <GeneratedMenu menu={child} key={child.id} />
        })}
      </>
    )
  }
  if (!menu.children)
    return (
      <AsideMenuItem
        to={menu.to}
        icon={menu.icon}
        title={menu.title}
        fontIcon={menu.fontIcon}
        hasBullet={menu.hasBullet}
        activePath={menu.activePath}
        badge={menu.badge}
        badgeCircle={menu.badgeCircle}
      />
    )

  return (
    <AsideMenuItemWithSub
      to={menu.to}
      title={menu.title}
      fontIcon={menu.fontIcon}
      icon={menu.icon}
      activePath={menu.activePath}
    >
      {menu.children.map((child) => {
        return <GeneratedMenu menu={child} key={child.id} />
      })}
    </AsideMenuItemWithSub>
  )
}

const filterMenus = (
  menus: Menu[] | undefined,
  predicate: (menu: Menu) => boolean
): Menu[] | undefined => {
  const result = menus?.map((menu, index) => ({
    ...menu,
    id: String(index),
    children: filterMenus(menu.children, predicate),
  }))
  return result?.filter((menu) => (!menu.children || menu.children.length > 0) && predicate(menu))
}

const AsideMenuData: React.FC = () => {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const menus = useMenus(user)

  const generated = useMemo(() => filterMenus(menus, (menu) => !Boolean(menu.hidden)), [menus])

  return (
    <>
      {generated?.map((child) => {
        return <GeneratedMenu menu={child} key={child.id} />
      })}
    </>
  )
}

export default AsideMenuData
